import React, { useEffect, useState } from "react";

import logo from "../logo/color_withname.png";
import { Link, Router, useNavigate } from "react-router-dom";
import { Cancel, Info, Phone, QuestionMark } from "@mui/icons-material";

export default function AuthContainer({ children, type, setType, alert }) {
  const [contact, setContact] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={`w-screen h-screen flex justify-center items-center border-b-5`}
    >
      <div className="fixed flex flex-col md:flex-row justify-between items-center top-0 p-2 pl-10 pr-10 bg-white shadow-lg w-screen rounded-lg">
        <img
          src={logo}
          style={{
            width: "12rem",
          }}
          alt=""
        />
        <ul className="flex gap-4 text-p1">
          <li>
            <Link className="flex items-center gap-1">
              <Info fontSize="20" /> About us
            </Link>
          </li>
          <li>
            <div className="relative">
              <p
                className="flex items-center gap-1 cursor-pointer select-none"
                onClick={() => {
                  setContact(!contact);
                }}
              >
                <Phone fontSize="20" /> Contact
              </p>
              <div
                className={`flex gap-2 ${
                  contact ? "absolute" : "hidden"
                } right-1 bg-white p-2 border-1 border-gray-300 items-center rounded-sm`}
              >
                <Cancel
                  fontSize="15"
                  className="cursor-pointer"
                  onClick={() => {
                    setContact(!contact);
                  }}
                />
                <a href="mailto:accesscareer.official@gmail.com?subject=Query - Login Form - AccessCareer">
                  accesscareer.official@gmail.com
                </a>
              </div>
            </div>
          </li>
          <li>
            <Link className="flex items-center gap-1">
              <QuestionMark fontSize="20" /> FAQ
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex gap-4 justify-center items-center flex-col p-5 bg-white border-1 border-gray-300 shadow-lg rounded-lg w-[95vw] md:w-[25vw]">
        {alert?.length > 0 && (
          <div className="p-2 bg-red-200 rounded-md">{alert}</div>
        )}
        <div className="flex w-full border-b-1 border-gray-300">
          <button
            className={`flex pb-3 p-2 justify-center font-semibold ${
              type && "border-b-2"
            }  border-black w-full`}
            onClick={() => {
              setType(!type);
            }}
          >
            Student
          </button>
          <button
            className={`flex pb-3 p-2 justify-center font-semibold ${
              !type && "border-b-2"
            }  border-black w-full`}
            onClick={() => {
              setType(!type);
            }}
          >
            Employer
          </button>
        </div>
        {children}
      </div>
      <div className="fixed bottom-0 p-2 bg-p3 w-full">
        <p className="float-right font-semibold text-white">AccessCareer</p>
      </div>
    </div>
  );
}
